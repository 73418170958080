import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import "./App.css";
import { Controller } from "react-hook-form";

import dayjs from "dayjs";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation, I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Assuming you set up i18n configuration in a file named i18n.js

import {
  Select,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormLabel,
  FormHelperText,
  FormControl,
  ThemeProvider,
  createTheme,
  Grid,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import AWS from "aws-sdk";
import * as StepFunctions from "aws-sdk/clients/stepfunctions";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const dynamoDb = new AWS.DynamoDB.DocumentClient();

const sts = new AWS.STS();

// Set the role ARN to assume
const roleToAssumeArn = "arn:aws:iam::559374061721:role/StepFunctionExecutor"; // Replace with the target role's ARN
const roleSessionName = "AssumedRoleSession"; // Replace with a desired session name

// Set the parameters for assuming the role
const assumeRoleParams = {
  RoleArn: roleToAssumeArn,
  RoleSessionName: roleSessionName,
  DurationSeconds: 3600, // Optional: Set the duration of the assumed role session in seconds
};

const putDataToDynamoDB = (item) => {
  item.id = Date.now().toString();
  const params = {
    TableName: "Pastors",
    Item: item,
  };

  return new Promise((resolve, reject) => {
    dynamoDb.put(params, (error, data) => {
      if (error) {
        reject(error);
        console.log(error);
      } else {
        resolve(data);
        console.log(data);
      }
    });
  });
};

export default function App() {
  const [consent, setConsent] = useState(false); // New state for consent checkbox

  const handleConsentChange = (e) => {
    setConsent(e.target.checked);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleChange = (e) => {
    changeLanguage(e.target.value);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const onSubmit = async (data) => {
    const formatDob = data.dateOfBirth.format("YYYY-MM-DD");
    const dynamoDbData = {
      ...data,
      dateOfBirth: formatDob,
      userCreatedDate: new Date().toISOString(),
      consent,
    };
    setIsSubmitting(true);
    try {
      await putDataToDynamoDB(dynamoDbData);
      console.log("Data successfully saved to DynamoDB!");

      // Assume the role
      sts.assumeRole(assumeRoleParams, async (err, temp) => {
        if (err) {
          console.error("Error assuming role:", err);
        } else {
          // Temporary credentials obtained
          const credentials = temp.Credentials;

          const stepfunctions = new StepFunctions({
            apiVersion: "2016-11-23",
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretAccessKey,
            sessionToken: credentials.SessionToken,
            region: "eu-west-2",
          });

          // Start the Step Function execution

          const params = {
            stateMachineArn:
              "arn:aws:states:eu-west-2:559374061721:stateMachine:MyStateMachine-bqyt7lj0t",
            input: JSON.stringify({
              ...data,
              formType: "pastorForm", // Add this line
            }),
          };

          const { executionArn } = await stepfunctions
            .startExecution(params)
            .promise();

          await new Promise(async (resolve, reject) => {
            let response = await stepfunctions
              .describeExecution({ executionArn })
              .promise();
            while (response.status !== "SUCCEEDED") {
              console.log(response);
              if (response.status === "FAILED") {
                reject(
                  "Registration failed. Please check logs for further details."
                );
                break;
              }
              response = await stepfunctions
                .describeExecution({ executionArn })
                .promise();
            }

            console.log(response.output);
            resolve(response.output[0]);
            if (response?.output[0]) {
              const [thinkificResponse, activeCampaignResponse] = JSON.parse(
                response.output
              );
              console.log(thinkificResponse);
              if (thinkificResponse.headers?.Location) {
                window.location.href = thinkificResponse.headers?.Location;
              } else {
                console.log(thinkificResponse.headers);
              }
            }
          }).then((response) => {
            console.log(response);
          });
        }
        setIsSubmitting(false);
      });
    } catch (error) {
      setIsSubmitting(false);
      if (error.message.includes("timeout")) {
        console.error("Timeout Error:", error);
      } else if (error.message.includes("invalid json")) {
        console.error("Invalid JSON Error:", error);
      } else {
        console.error("General Error:", error);
      }
      throw error; // Ensure the error is thrown so the Step Function knows about the failure.
    }
  };
  const defaultDate = dayjs("1990-01-01");
  const maxDateForEighteenYearsOld = dayjs().subtract(18, "year");
  const theme = createTheme({
    palette: {
      primary: { main: "#385C82" },
      secondary: { main: "#DC6727" },
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isEighteenOrOlder = (value) => {
    const age = dayjs().diff(dayjs(value), "year");
    return age >= 18;
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WMZLBHSLNT"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-WMZLBHSLNT');
        `}
        </script>
      </Helmet>

      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Container
            maxWidth="lg"
            style={{
              marginTop: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ float: "right;", pr: 2, pb: 5 }}>
              <Select defaultValue="en" onChange={handleChange}>
                <MenuItem value="en">
                  <span class="flag-icon flag-icon-gb"></span>&nbsp; English
                </MenuItem>
                <MenuItem value="pt">
                  <span class="flag-icon flag-icon-pt"></span>&nbsp; PortuguÃªs
                </MenuItem>
              </Select>
            </Box>

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: { sm: "90%" } }}
            >
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                {/* Main Header */}
                <img
                  src="https://equippedmedia.s3.af-south-1.amazonaws.com/img/header.svg
"
                  width="40%"
                  alt="Description of Image"
                  id="dynamicSvg"
                />
                <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
                  {t("header")}
                </h1>
                <h2 style={{ fontSize: "24px", margin: "10px 0" }}>
                  {t("subHeader")}
                </h2>

                {/* Sub-heading */}
                <p
                  style={{
                    fontSize: "16px",
                    maxWidth: "100%",
                    margin: "0 auto",
                  }}
                >
                  {t("description")}
                </p>
              </div>

              {/* Sub-heading */}
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  margin: "20px 0",
                }}
              >
                <p></p>
                {t("basicInfo")}
              </p>
              <p></p>
              <FormLabel component="legend">{t("firstNameQuestion")}</FormLabel>
              <TextField
                fullWidth
                placeholder="First Name"
                variant="outlined"
                {...register("firstName", {
                  required: true,
                  maxLength: 80,
                })}
                error={!!errors.firstName}
                helperText={errors.firstName ? "First name is required." : ""}
              />
              <p></p>
              <FormLabel component="legend">{t("lastNameQuestion")}</FormLabel>
              <TextField
                fullWidth
                placeholder="Last Name / Surname"
                variant="outlined"
                {...register("lastName", {
                  required: true,
                  maxLength: 80,
                })}
                error={!!errors.lastName}
                helperText={errors.lastName ? "Last name is required." : ""}
              />
              <p></p>
              {/* Gender Dropdown */}
              <FormLabel component="legend">{t("genderQuestion")}</FormLabel>
              <FormControl fullWidth variant="outlined" error={!!errors.gender}>
                <Select
                  id="gender"
                  {...register("gender", {
                    required: "This field is required.",
                    validate: (value) => value !== "" || "Select your gender",
                  })}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>{t("genderQuestion")}</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>{t("genderQuestion")}</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.gender ? errors.gender.message : ""}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Date of Birth */}
              <FormLabel component="legend">{t("dobQuestion")}</FormLabel>

              <div style={{ width: "100%" }}>
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <Controller
                    fullWidth
                    name="dateOfBirth"
                    control={control}
                    defaultValue={defaultDate}
                    rules={{
                      required: "This field is required.",
                      pattern: {
                        value:
                          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
                        message:
                          "Please enter a valid date in the format YYYY-MM-DD.",
                      },
                      validate: {
                        isEighteenOrOlder: (value) =>
                          isEighteenOrOlder(value) ||
                          "You must be 18 years or older.",
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        maxDate={maxDateForEighteenYearsOld}
                        views={["year", "month", "day"]}
                        inputFormat="YYYY-MM-DD"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors["dateOfBirth"]}
                            helperText={errors["dateOfBirth"]?.message}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <p></p>
              <FormLabel component="phone">{t("cellPhoneQuestion")}</FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="country-code"
                    variant="outlined"
                    placeholder="Country Code +2"
                    {...register("countryCode", {
                      required: true,
                      pattern: /^\+2\d{1}\d*$/,
                    })}
                    error={!!errors["countryCode"]}
                    helperText={
                      errors["countryCode"]
                        ? "Country code must start with +2XX and contain only digits."
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="phone-number"
                    variant="outlined"
                    placeholder="Phone Number"
                    {...register("phoneNumber", {
                      required: true,
                      pattern: /^\d{8,12}$/,
                    })}
                    error={!!errors["phoneNumber"]}
                    helperText={
                      errors["phoneNumber"]
                        ? "Phone number must be 8 to 12 digits long."
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <p></p>
              <FormLabel component="legend">{t("emailQuestion")}</FormLabel>
              <TextField
                fullWidth
                placeholder="Email Address"
                variant="outlined"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={!!errors.email}
                helperText={
                  errors.email ? "Please enter a valid email address." : ""
                }
              />
              <p></p>
              {/* Occupation or Area of Study */}
              <FormLabel component="legend">
                {t("occupationQuestion")}
              </FormLabel>
              <TextField
                fullWidth
                id="occupation"
                placeholder="Occupation"
                variant="outlined"
                {...register("occupation", { required: true })}
                error={!!errors["occupation"]}
                helperText={
                  errors["occupation"] ? "This field is required." : ""
                }
              />
              <p></p>
              {/* Marriage Dropdown */}
              <FormLabel component="legend">
                {t("maritalStatusQuestion")}
              </FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.marriageStatus}
              >
                <Select
                  id="marriage-status"
                  {...register("marriageStatus", {
                    required: "Please select either Yes or No.",
                    validate: (value) =>
                      value !== "" || "Please select either Yes or No.",
                  })}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Yes/No</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Yes/No</em>
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.marriageStatus ? (
                    <FormHelperText>
                      {errors.marriageStatus.message}
                    </FormHelperText>
                  ) : null}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Children Dropdown */}
              <FormLabel component="legend">{t("childrenQuestion")}</FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.childrenInfo}
              >
                <Select
                  label="Children:"
                  id="children-info"
                  {...register("childrenInfo", {
                    required: "Please select either Yes or No.",
                    validate: (value) =>
                      value !== "" || "Please select either Yes or No.",
                  })}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Yes/No</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Yes/No</em>
                  </MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.childrenInfo ? (
                    <FormHelperText>
                      {errors.childrenInfo.message}
                    </FormHelperText>
                  ) : null}
                </FormHelperText>
              </FormControl>

              {/* LANGUAGE Sub-heading */}
              <h3
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  margin: "20px 0",
                }}
              >
                {t("languageHeader")}
              </h3>

              {/* First Language Text Field */}
              <FormLabel component="legend">
                {t("firstLanguageQuestion")}
              </FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.firstLanguage}
              >
                <TextField
                  id="first-language"
                  {...register("firstLanguage", {
                    required: "This field is required.",
                  })}
                  placeholder="Mother tongue Language"
                  variant="outlined"
                  fullWidth
                  error={!!errors.firstLanguage}
                />
                <FormHelperText>
                  {errors.firstLanguage ? errors.firstLanguage.message : ""}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* First Language Level */}
              <FormLabel component="legend">
                {t("firstLanguageLevelQuestion")}
              </FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.firstLanguageLevel}
              >
                <Select
                  id="first-language-level"
                  {...register("firstLanguageLevel", {
                    required: "Please select your language level.",
                    validate: (value) =>
                      value !== "" || "Please select your language level.",
                  })}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Select your level</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select your level</em>
                  </MenuItem>
                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Fair">Fair</MenuItem>
                  <MenuItem value="Poor">Poor</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.firstLanguageLevel
                    ? errors.firstLanguageLevel.message
                    : ""}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Second Language Text Field */}
              <FormLabel component="legend">
                {t("secondLanguageQuestion")}
              </FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="second-language"
                  {...register("secondLanguage", {
                    required: "This field is required.",
                  })}
                  placeholder="Second Language"
                  variant="outlined"
                  fullWidth
                  error={!!errors.secondLanguage}
                  helperText={
                    errors.secondLanguage ? errors.secondLanguage.message : ""
                  }
                />
              </FormControl>
              <p></p>
              {/* Second Language Level */}
              <FormLabel component="legend">
                {t("secondLanguageLevelQuestion")}
              </FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.secondLanguageLevel}
              >
                <Select
                  id="second-language-level"
                  {...register("secondLanguageLevel", {
                    required: "Please select your language level.",
                    validate: (value) =>
                      value !== "" || "Please select your language level.",
                  })}
                  defaultValue=""
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Select your level</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select your level</em>
                  </MenuItem>
                  <MenuItem value="Good">Good</MenuItem>
                  <MenuItem value="Fair">Fair</MenuItem>
                  <MenuItem value="Poor">Poor</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.secondLanguageLevel
                    ? errors.secondLanguageLevel.message
                    : ""}
                </FormHelperText>
              </FormControl>

              {/* RESIDENCE Sub-heading */}
              <h3
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  margin: "20px 0",
                }}
              >
                {t("residenceHeader")}
              </h3>
              <p></p>
              <FormLabel component="legend">
                {t("nationalityQuestion")}
              </FormLabel>
              {/* Nationality Dropdown */}
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors["nationality"]}
              >
                <Select
                  id="nationality"
                  defaultValue=""
                  displayEmpty
                  {...register("nationality", {
                    required: "This field is required",
                  })}
                  error={!!errors["nationality"]}
                >
                  <MenuItem value="" disabled>
                    <em>Select Your Nationality</em>
                  </MenuItem>
                  {/* Add other options as needed */}
                  <MenuItem value="Afghan">Afghan</MenuItem>
                  <MenuItem value=" Ãland Island"> Ãland Island</MenuItem>
                  <MenuItem value=" Albanian"> Albanian</MenuItem>
                  <MenuItem value=" Algerian"> Algerian</MenuItem>
                  <MenuItem value=" American Samoan"> American Samoan</MenuItem>
                  <MenuItem value=" Andorran"> Andorran</MenuItem>
                  <MenuItem value=" Angolan"> Angolan</MenuItem>
                  <MenuItem value=" Anguillan"> Anguillan</MenuItem>
                  <MenuItem value=" Antarctic"> Antarctic</MenuItem>
                  <MenuItem value=" Antiguan or Barbudan">
                    {" "}
                    Antiguan or Barbudan
                  </MenuItem>
                  <MenuItem value=" Argentine"> Argentine</MenuItem>
                  <MenuItem value=" Armenian"> Armenian</MenuItem>
                  <MenuItem value=" Aruban"> Aruban</MenuItem>
                  <MenuItem value=" Australian"> Australian</MenuItem>
                  <MenuItem value=" Austrian"> Austrian</MenuItem>
                  <MenuItem value=" Azerbaijani, Azeri">
                    {" "}
                    Azerbaijani, Azeri
                  </MenuItem>
                  <MenuItem value=" Bahamian"> Bahamian</MenuItem>
                  <MenuItem value=" Bahraini"> Bahraini</MenuItem>
                  <MenuItem value=" Bangladeshi"> Bangladeshi</MenuItem>
                  <MenuItem value=" Barbadian"> Barbadian</MenuItem>
                  <MenuItem value=" Belarusian"> Belarusian</MenuItem>
                  <MenuItem value=" Belgian"> Belgian</MenuItem>
                  <MenuItem value=" Belizean"> Belizean</MenuItem>
                  <MenuItem value=" Beninese, Beninois">
                    {" "}
                    Beninese, Beninois
                  </MenuItem>
                  <MenuItem value=" Bermudian, Bermudan">
                    {" "}
                    Bermudian, Bermudan
                  </MenuItem>
                  <MenuItem value=" Bhutanese"> Bhutanese</MenuItem>
                  <MenuItem value=" Bolivian"> Bolivian</MenuItem>
                  <MenuItem value=" Bonaire"> Bonaire</MenuItem>
                  <MenuItem value=" Bosnian or Herzegovinian">
                    {" "}
                    Bosnian or Herzegovinian
                  </MenuItem>
                  <MenuItem value=" Motswana, Botswanan">
                    {" "}
                    Motswana, Botswanan
                  </MenuItem>
                  <MenuItem value=" Bouvet Island"> Bouvet Island</MenuItem>
                  <MenuItem value=" Brazilian"> Brazilian</MenuItem>
                  <MenuItem value=" BIOT"> BIOT</MenuItem>
                  <MenuItem value=" Bruneian"> Bruneian</MenuItem>
                  <MenuItem value=" Bulgarian"> Bulgarian</MenuItem>
                  <MenuItem value=" BurkinabÃ©"> BurkinabÃ©</MenuItem>
                  <MenuItem value=" Burundian"> Burundian</MenuItem>
                  <MenuItem value=" Cabo Verdean"> Cabo Verdean</MenuItem>
                  <MenuItem value=" Cambodian"> Cambodian</MenuItem>
                  <MenuItem value=" Cameroonian"> Cameroonian</MenuItem>
                  <MenuItem value=" Canadian"> Canadian</MenuItem>
                  <MenuItem value=" Caymanian"> Caymanian</MenuItem>
                  <MenuItem value=" Central African"> Central African</MenuItem>
                  <MenuItem value=" Chadian"> Chadian</MenuItem>
                  <MenuItem value=" Chilean"> Chilean</MenuItem>
                  <MenuItem value=" Chinese"> Chinese</MenuItem>
                  <MenuItem value=" Christmas Island">
                    {" "}
                    Christmas Island
                  </MenuItem>
                  <MenuItem value=" Cocos Island"> Cocos Island</MenuItem>
                  <MenuItem value=" Colombian"> Colombian</MenuItem>
                  <MenuItem value=" Comoran, Comorian">
                    {" "}
                    Comoran, Comorian
                  </MenuItem>
                  <MenuItem value=" Congolese"> Congolese</MenuItem>
                  <MenuItem value=" Congolese"> Congolese</MenuItem>
                  <MenuItem value=" Cook Island"> Cook Island</MenuItem>
                  <MenuItem value=" Costa Rican"> Costa Rican</MenuItem>
                  <MenuItem value=" Ivorian"> Ivorian</MenuItem>
                  <MenuItem value=" Croatian"> Croatian</MenuItem>
                  <MenuItem value=" Cuban"> Cuban</MenuItem>
                  <MenuItem value=" CuraÃ§aoan"> CuraÃ§aoan</MenuItem>
                  <MenuItem value=" Cypriot"> Cypriot</MenuItem>
                  <MenuItem value=" Czech"> Czech</MenuItem>
                  <MenuItem value=" Danish"> Danish</MenuItem>
                  <MenuItem value=" Djiboutian"> Djiboutian</MenuItem>
                  <MenuItem value=" Dominican"> Dominican</MenuItem>
                  <MenuItem value=" Dominican"> Dominican</MenuItem>
                  <MenuItem value=" Ecuadorian"> Ecuadorian</MenuItem>
                  <MenuItem value=" Egyptian"> Egyptian</MenuItem>
                  <MenuItem value=" Salvadoran"> Salvadoran</MenuItem>
                  <MenuItem value=" Equatorial Guinean, Equatoguinean">
                    {" "}
                    Equatorial Guinean, Equatoguinean
                  </MenuItem>
                  <MenuItem value=" Eritrean"> Eritrean</MenuItem>
                  <MenuItem value=" Estonian"> Estonian</MenuItem>
                  <MenuItem value=" Ethiopian"> Ethiopian</MenuItem>
                  <MenuItem value=" Falkland Island"> Falkland Island</MenuItem>
                  <MenuItem value=" Faroese"> Faroese</MenuItem>
                  <MenuItem value=" Fijian"> Fijian</MenuItem>
                  <MenuItem value=" Finnish"> Finnish</MenuItem>
                  <MenuItem value=" French"> French</MenuItem>
                  <MenuItem value=" French Guianese"> French Guianese</MenuItem>
                  <MenuItem value=" French Polynesian">
                    {" "}
                    French Polynesian
                  </MenuItem>
                  <MenuItem value=" French Southern Territories">
                    {" "}
                    French Southern Territories
                  </MenuItem>
                  <MenuItem value=" Gabonese"> Gabonese</MenuItem>
                  <MenuItem value=" Gambian"> Gambian</MenuItem>
                  <MenuItem value=" Georgian"> Georgian</MenuItem>
                  <MenuItem value=" German"> German</MenuItem>
                  <MenuItem value=" Ghanaian"> Ghanaian</MenuItem>
                  <MenuItem value=" Gibraltar"> Gibraltar</MenuItem>
                  <MenuItem value=" Greek, Hellenic"> Greek, Hellenic</MenuItem>
                  <MenuItem value=" Greenlandic"> Greenlandic</MenuItem>
                  <MenuItem value=" Grenadian"> Grenadian</MenuItem>
                  <MenuItem value=" Guadeloupe"> Guadeloupe</MenuItem>
                  <MenuItem value=" Guamanian, Guambat">
                    {" "}
                    Guamanian, Guambat
                  </MenuItem>
                  <MenuItem value=" Guatemalan"> Guatemalan</MenuItem>
                  <MenuItem value=" Channel Island"> Channel Island</MenuItem>
                  <MenuItem value=" Guinean"> Guinean</MenuItem>
                  <MenuItem value=" Bissau-Guinean"> Bissau-Guinean</MenuItem>
                  <MenuItem value=" Guyanese"> Guyanese</MenuItem>
                  <MenuItem value=" Haitian"> Haitian</MenuItem>
                  <MenuItem value=" Heard Island or McDonald Islands">
                    {" "}
                    Heard Island or McDonald Islands
                  </MenuItem>
                  <MenuItem value=" Vatican"> Vatican</MenuItem>
                  <MenuItem value=" Honduran"> Honduran</MenuItem>
                  <MenuItem value=" Hong Kong, Hong Kongese">
                    {" "}
                    Hong Kong, Hong Kongese
                  </MenuItem>
                  <MenuItem value=" Hungarian, Magyar">
                    {" "}
                    Hungarian, Magyar
                  </MenuItem>
                  <MenuItem value=" Icelandic"> Icelandic</MenuItem>
                  <MenuItem value=" Indian"> Indian</MenuItem>
                  <MenuItem value=" Indonesian"> Indonesian</MenuItem>
                  <MenuItem value=" Iranian, Persian">
                    {" "}
                    Iranian, Persian
                  </MenuItem>
                  <MenuItem value=" Iraqi"> Iraqi</MenuItem>
                  <MenuItem value=" Irish"> Irish</MenuItem>
                  <MenuItem value=" Manx"> Manx</MenuItem>
                  <MenuItem value=" Israeli"> Israeli</MenuItem>
                  <MenuItem value=" Italian"> Italian</MenuItem>
                  <MenuItem value=" Jamaican"> Jamaican</MenuItem>
                  <MenuItem value=" Japanese"> Japanese</MenuItem>
                  <MenuItem value=" Channel Island"> Channel Island</MenuItem>
                  <MenuItem value=" Jordanian"> Jordanian</MenuItem>
                  <MenuItem value=" Kazakhstani, Kazakh">
                    {" "}
                    Kazakhstani, Kazakh
                  </MenuItem>
                  <MenuItem value=" Kenyan"> Kenyan</MenuItem>
                  <MenuItem value=" I-Kiribati"> I-Kiribati</MenuItem>
                  <MenuItem value=" North Korean"> North Korean</MenuItem>
                  <MenuItem value=" South Korean"> South Korean</MenuItem>
                  <MenuItem value=" Kuwaiti"> Kuwaiti</MenuItem>
                  <MenuItem value=" Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz">
                    {" "}
                    Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz
                  </MenuItem>
                  <MenuItem value=" Lao, Laotian"> Lao, Laotian</MenuItem>
                  <MenuItem value=" Latvian"> Latvian</MenuItem>
                  <MenuItem value=" Lebanese"> Lebanese</MenuItem>
                  <MenuItem value=" Basotho"> Basotho</MenuItem>
                  <MenuItem value=" Liberian"> Liberian</MenuItem>
                  <MenuItem value=" Libyan"> Libyan</MenuItem>
                  <MenuItem value=" Liechtenstein"> Liechtenstein</MenuItem>
                  <MenuItem value=" Lithuanian"> Lithuanian</MenuItem>
                  <MenuItem value=" Luxembourg, Luxembourgish">
                    {" "}
                    Luxembourg, Luxembourgish
                  </MenuItem>
                  <MenuItem value=" Macanese, Chinese">
                    {" "}
                    Macanese, Chinese
                  </MenuItem>
                  <MenuItem value=" Macedonian"> Macedonian</MenuItem>
                  <MenuItem value=" Malagasy"> Malagasy</MenuItem>
                  <MenuItem value=" Malawian"> Malawian</MenuItem>
                  <MenuItem value=" Malaysian"> Malaysian</MenuItem>
                  <MenuItem value=" Maldivian"> Maldivian</MenuItem>
                  <MenuItem value=" Malian, Malinese">
                    {" "}
                    Malian, Malinese
                  </MenuItem>
                  <MenuItem value=" Maltese"> Maltese</MenuItem>
                  <MenuItem value=" Marshallese"> Marshallese</MenuItem>
                  <MenuItem value=" Martiniquais, Martinican">
                    {" "}
                    Martiniquais, Martinican
                  </MenuItem>
                  <MenuItem value=" Mauritanian"> Mauritanian</MenuItem>
                  <MenuItem value=" Mauritian"> Mauritian</MenuItem>
                  <MenuItem value=" Mahoran"> Mahoran</MenuItem>
                  <MenuItem value=" Mexican"> Mexican</MenuItem>
                  <MenuItem value=" Micronesian"> Micronesian</MenuItem>
                  <MenuItem value=" Moldovan"> Moldovan</MenuItem>
                  <MenuItem value=" MonÃ©gasque, Monacan">
                    {" "}
                    MonÃ©gasque, Monacan
                  </MenuItem>
                  <MenuItem value=" Mongolian"> Mongolian</MenuItem>
                  <MenuItem value=" Montenegrin"> Montenegrin</MenuItem>
                  <MenuItem value=" Montserratian"> Montserratian</MenuItem>
                  <MenuItem value=" Moroccan"> Moroccan</MenuItem>
                  <MenuItem value=" Mozambican"> Mozambican</MenuItem>
                  <MenuItem value=" Burmese"> Burmese</MenuItem>
                  <MenuItem value=" Namibian"> Namibian</MenuItem>
                  <MenuItem value=" Nauruan"> Nauruan</MenuItem>
                  <MenuItem value=" Nepali, Nepalese">
                    {" "}
                    Nepali, Nepalese
                  </MenuItem>
                  <MenuItem value=" Dutch, Netherlandic">
                    {" "}
                    Dutch, Netherlandic
                  </MenuItem>
                  <MenuItem value=" New Caledonian"> New Caledonian</MenuItem>
                  <MenuItem value=" New Zealand, NZ"> New Zealand, NZ</MenuItem>
                  <MenuItem value=" Nicaraguan"> Nicaraguan</MenuItem>
                  <MenuItem value=" Nigerien"> Nigerien</MenuItem>
                  <MenuItem value=" Nigerian"> Nigerian</MenuItem>
                  <MenuItem value=" Niuean"> Niuean</MenuItem>
                  <MenuItem value=" Norfolk Island"> Norfolk Island</MenuItem>
                  <MenuItem value=" Northern Marianan">
                    {" "}
                    Northern Marianan
                  </MenuItem>
                  <MenuItem value=" Norwegian"> Norwegian</MenuItem>
                  <MenuItem value=" Omani"> Omani</MenuItem>
                  <MenuItem value=" Pakistani"> Pakistani</MenuItem>
                  <MenuItem value=" Palauan"> Palauan</MenuItem>
                  <MenuItem value=" Palestinian"> Palestinian</MenuItem>
                  <MenuItem value=" Panamanian"> Panamanian</MenuItem>
                  <MenuItem value=" Papua New Guinean, Papuan">
                    {" "}
                    Papua New Guinean, Papuan
                  </MenuItem>
                  <MenuItem value=" Paraguayan"> Paraguayan</MenuItem>
                  <MenuItem value=" Peruvian"> Peruvian</MenuItem>
                  <MenuItem value=" Philippine, Filipino">
                    {" "}
                    Philippine, Filipino
                  </MenuItem>
                  <MenuItem value=" Pitcairn Island"> Pitcairn Island</MenuItem>
                  <MenuItem value=" Polish"> Polish</MenuItem>
                  <MenuItem value=" Portuguese"> Portuguese</MenuItem>
                  <MenuItem value=" Puerto Rican"> Puerto Rican</MenuItem>
                  <MenuItem value=" Qatari"> Qatari</MenuItem>
                  <MenuItem value=" RÃ©unionese, RÃ©unionnais">
                    {" "}
                    RÃ©unionese, RÃ©unionnais
                  </MenuItem>
                  <MenuItem value=" Romanian"> Romanian</MenuItem>
                  <MenuItem value=" Russian"> Russian</MenuItem>
                  <MenuItem value=" Rwandan"> Rwandan</MenuItem>
                  <MenuItem value=" BarthÃ©lemois"> BarthÃ©lemois</MenuItem>
                  <MenuItem value=" Saint Helenian"> Saint Helenian</MenuItem>
                  <MenuItem value=" Kittitian or Nevisian">
                    {" "}
                    Kittitian or Nevisian
                  </MenuItem>
                  <MenuItem value=" Saint Lucian"> Saint Lucian</MenuItem>
                  <MenuItem value=" Saint-Martinoise">
                    {" "}
                    Saint-Martinoise
                  </MenuItem>
                  <MenuItem value=" Saint-Pierrais or Miquelonnais">
                    {" "}
                    Saint-Pierrais or Miquelonnais
                  </MenuItem>
                  <MenuItem value=" Saint Vincentian, Vincentian">
                    {" "}
                    Saint Vincentian, Vincentian
                  </MenuItem>
                  <MenuItem value=" Samoan"> Samoan</MenuItem>
                  <MenuItem value=" Sammarinese"> Sammarinese</MenuItem>
                  <MenuItem value=" SÃ£o TomÃ©an"> SÃ£o TomÃ©an</MenuItem>
                  <MenuItem value=" Saudi, Saudi Arabian">
                    {" "}
                    Saudi, Saudi Arabian
                  </MenuItem>
                  <MenuItem value=" Senegalese"> Senegalese</MenuItem>
                  <MenuItem value=" Serbian"> Serbian</MenuItem>
                  <MenuItem value=" Seychellois"> Seychellois</MenuItem>
                  <MenuItem value=" Sierra Leonean"> Sierra Leonean</MenuItem>
                  <MenuItem value=" Singaporean"> Singaporean</MenuItem>
                  <MenuItem value=" Sint Maarten"> Sint Maarten</MenuItem>
                  <MenuItem value=" Slovak"> Slovak</MenuItem>
                  <MenuItem value=" Slovenian, Slovene">
                    {" "}
                    Slovenian, Slovene
                  </MenuItem>
                  <MenuItem value=" Solomon Island"> Solomon Island</MenuItem>
                  <MenuItem value=" Somali, Somalian">
                    {" "}
                    Somali, Somalian
                  </MenuItem>
                  <MenuItem value=" South African"> South African</MenuItem>
                  <MenuItem value=" South Georgia or South Sandwich Islands">
                    {" "}
                    South Georgia or South Sandwich Islands
                  </MenuItem>
                  <MenuItem value=" South Sudanese"> South Sudanese</MenuItem>
                  <MenuItem value=" Spanish"> Spanish</MenuItem>
                  <MenuItem value=" Sri Lankan"> Sri Lankan</MenuItem>
                  <MenuItem value=" Sudanese"> Sudanese</MenuItem>
                  <MenuItem value=" Surinamese"> Surinamese</MenuItem>
                  <MenuItem value=" Svalbard"> Svalbard</MenuItem>
                  <MenuItem value=" Swazi"> Swazi</MenuItem>
                  <MenuItem value=" Swedish"> Swedish</MenuItem>
                  <MenuItem value=" Swiss"> Swiss</MenuItem>
                  <MenuItem value=" Syrian"> Syrian</MenuItem>
                  <MenuItem value=" Chinese, Taiwanese">
                    {" "}
                    Chinese, Taiwanese
                  </MenuItem>
                  <MenuItem value=" Tajikistani"> Tajikistani</MenuItem>
                  <MenuItem value=" Tanzanian"> Tanzanian</MenuItem>
                  <MenuItem value=" Thai"> Thai</MenuItem>
                  <MenuItem value=" Timorese"> Timorese</MenuItem>
                  <MenuItem value=" Togolese"> Togolese</MenuItem>
                  <MenuItem value=" Tokelauan"> Tokelauan</MenuItem>
                  <MenuItem value=" Tongan"> Tongan</MenuItem>
                  <MenuItem value=" Trinidadian or Tobagonian">
                    {" "}
                    Trinidadian or Tobagonian
                  </MenuItem>
                  <MenuItem value=" Tunisian"> Tunisian</MenuItem>
                  <MenuItem value=" Turkish"> Turkish</MenuItem>
                  <MenuItem value=" Turkmen"> Turkmen</MenuItem>
                  <MenuItem value=" Turks and Caicos Island">
                    {" "}
                    Turks and Caicos Island
                  </MenuItem>
                  <MenuItem value=" Tuvaluan"> Tuvaluan</MenuItem>
                  <MenuItem value=" Ugandan"> Ugandan</MenuItem>
                  <MenuItem value=" Ukrainian"> Ukrainian</MenuItem>
                  <MenuItem value=" Emirati, Emirian, Emiri">
                    {" "}
                    Emirati, Emirian, Emiri
                  </MenuItem>
                  <MenuItem value=" British, UK"> British, UK</MenuItem>
                  <MenuItem value=" American"> American</MenuItem>
                  <MenuItem value=" American"> American</MenuItem>
                  <MenuItem value=" Uruguayan"> Uruguayan</MenuItem>
                  <MenuItem value=" Uzbekistani, Uzbek">
                    {" "}
                    Uzbekistani, Uzbek
                  </MenuItem>
                  <MenuItem value=" Ni-Vanuatu, Vanuatuan">
                    {" "}
                    Ni-Vanuatu, Vanuatuan
                  </MenuItem>
                  <MenuItem value=" Venezuelan"> Venezuelan</MenuItem>
                  <MenuItem value=" Vietnamese"> Vietnamese</MenuItem>
                  <MenuItem value=" British Virgin Island">
                    {" "}
                    British Virgin Island
                  </MenuItem>
                  <MenuItem value=" U.S. Virgin Island">
                    {" "}
                    U.S. Virgin Island
                  </MenuItem>
                  <MenuItem value=" Wallis and Futuna, Wallisian or Futunan">
                    {" "}
                    Wallis and Futuna, Wallisian or Futunan
                  </MenuItem>
                  <MenuItem value=" Sahrawi, Sahrawian, Sahraouian">
                    {" "}
                    Sahrawi, Sahrawian, Sahraouian
                  </MenuItem>
                  <MenuItem value=" Yemeni"> Yemeni</MenuItem>
                  <MenuItem value=" Zambian"> Zambian</MenuItem>
                  <MenuItem value=" Zimbabwean"> Zimbabwean</MenuItem>
                  {/* ... other countries ... */}
                </Select>
                <FormHelperText>
                  {errors["nationality"] && errors["nationality"].message}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Country of Residence Dropdown */}
              <FormLabel component="legend">{t("countryQuestion")}</FormLabel>
              <FormControl
                fullWidth
                placeholder="City"
                variant="outlined"
                error={!!errors["countryOfResidence"]}
              >
                <Select
                  id="country-of-residence"
                  defaultValue=""
                  displayEmpty
                  {...register("countryOfResidence", {
                    required: "This field is required.",
                  })}
                  error={!!errors["countryOfResidence"]}
                >
                  <MenuItem value="" disabled>
                    <em>Select Your Country</em>
                  </MenuItem>
                  <MenuItem value="South Africa">South Africa</MenuItem>
                  <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                  <MenuItem value="Botswana">Botswana</MenuItem>
                  <MenuItem value="Malawi">Malawi</MenuItem>
                  <MenuItem value="Namibia">Namibia</MenuItem>
                  {/* ... other countries ... */}
                </Select>
                <FormHelperText>
                  {errors["countryOfResidence"] &&
                    errors["countryOfResidence"].message}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* City of Residence Text Field */}
              <FormLabel component="legend">{t("cityQuestion")}</FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors["cityOfResidence"]}
              >
                <TextField
                  id="city-of-residence"
                  placeholder="City"
                  variant="outlined"
                  fullWidth
                  {...register("cityOfResidence", {
                    required: "This field is required.",
                  })}
                  error={!!errors["cityOfResidence"]}
                />
                <FormHelperText>
                  {errors["cityOfResidence"] &&
                    errors["cityOfResidence"].message}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Physical Address Text Field */}
              <FormLabel component="legend">{t("addressQuestion")}</FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors["physicalAddress"]}
              >
                <TextField
                  id="physical-address"
                  placeholder="Number, Road, Suburb"
                  variant="outlined"
                  fullWidth
                  {...register("physicalAddress", {
                    required: "This is a required field.",
                  })}
                  error={!!errors["physicalAddress"]}
                />
                <FormHelperText>
                  {errors["physicalAddress"] &&
                    errors["physicalAddress"].message}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Alternative Address */}
              <FormLabel component="legend">
                {t("alternativeAddressQuestion")}
              </FormLabel>
              <TextField
                placeholder="Number, Road, Suburb"
                fullWidth
                id="alternative-address"
                variant="outlined"
                {...register("alternativeAddress", { required: true })}
                error={!!errors["alternativeAddress"]}
                helperText={
                  errors["alternativeAddress"] ? "This field is required." : ""
                }
              />

              {/* PASTORAL INFORMATION Sub-heading */}
              <h3
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  margin: "20px 0",
                }}
              >
                {t("pastoralInfo")}
              </h3>

              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                {/* Main Header */}

                {/* Sub-heading */}
                <p
                  style={{
                    fontSize: "16px",
                    maxWidth: "100%",
                    margin: "0 auto",
                  }}
                >
                  {t("pastorsubheading")}
                </p>
              </div>

              {/* Church Name Text Field */}
              <FormLabel component="legend">
                {t("churchNameQuestion")}
              </FormLabel>

              <FormControl fullWidth variant="outlined">
                <TextField
                  id="church-name"
                  {...register("churchName", {
                    required: "This field is required.",
                  })}
                  placeholder="Church Name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.churchName}
                  helperText={
                    errors.churchName ? errors.churchName.message : ""
                  }
                />
              </FormControl>
              <p></p>
              {/* Denomination Text Field */}
              <FormLabel component="legend">
                {t("denominationQuestion")}
              </FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="denomination"
                  {...register("denomination", {
                    required: "This field is required.",
                  })}
                  placeholder="Denomination"
                  variant="outlined"
                  fullWidth
                  error={!!errors.denomination}
                  helperText={
                    errors.denomination ? errors.denomination.message : ""
                  }
                />
              </FormControl>
              <p></p>
              {/* Congregation Size Text Field */}
              <FormLabel component="legend">
                {t("congregationSizeQuestion")}
              </FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="congregation-size"
                  {...register("congregationSize", {
                    required: "This field is required.",
                  })}
                  placeholder="Congregation Size"
                  variant="outlined"
                  fullWidth
                  error={!!errors.congregationSize}
                  helperText={
                    errors.congregationSize
                      ? errors.congregationSize.message
                      : ""
                  }
                />
              </FormControl>
              <p></p>
              {/* Current Position within the Church Text Field */}
              <FormLabel component="legend">{t("positionQuestion")}</FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="church-position"
                  {...register("churchPosition", {
                    required: "This field is required.",
                  })}
                  placeholder="Head Pastor/Pastor/Junior Pastor/If other please specify"
                  variant="outlined"
                  fullWidth
                  error={!!errors.churchPosition}
                  helperText={
                    errors.churchPosition ? errors.churchPosition.message : ""
                  }
                />
              </FormControl>
              <p></p>
              {/* Practicing as a Pastor Select Field */}
              <FormLabel component="legend">
                {t("practicingPastorQuestion")}
              </FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.pastorStatus}
              >
                <Select
                  id="pastor-status"
                  {...register("pastorStatus", {
                    required: "You need to make a selection.",
                  })}
                  defaultValue=""
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Current/Previous</em>
                  </MenuItem>
                  <MenuItem value="Current">Current</MenuItem>
                  <MenuItem value="Previous">Previous</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.pastorStatus ? errors.pastorStatus.message : ""}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* Number of Years Practicing as a Pastor Text Field */}
              <FormLabel component="legend">
                {t("yearsPracticeQuestion")}
              </FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="years-as-pastor"
                  {...register("yearsAsPastor", {
                    required: "This field is required.",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number.",
                    },
                  })}
                  placeholder="Number of Years"
                  variant="outlined"
                  fullWidth
                  error={!!errors.yearsAsPastor}
                  helperText={
                    errors.yearsAsPastor ? errors.yearsAsPastor.message : ""
                  }
                />
              </FormControl>

              {/* REFERRAL INFORMATION Sub-heading */}
              <h3
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  margin: "20px 0",
                }}
              >
                {t("referralHeader")}
              </h3>
              <FormLabel component="legend">{t("referralCode")}</FormLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors["referralCode"]}
              >
                <Select
                  labelId="referral-code-label"
                  id="referral-code"
                  defaultValue=""
                  displayEmpty
                  {...register("referralCode", {
                    required: "This field is required.",
                  })}
                  error={!!errors["referralCode"]}
                >
                  <MenuItem value="" disabled>
                    <em>Select your referral code</em>
                  </MenuItem>
                  <MenuItem value="YG">YG</MenuItem>
                  <MenuItem value="SF">SF</MenuItem>
                  <MenuItem value="WYG">WYG</MenuItem>
                  <MenuItem value="WG">WG</MenuItem>
                  <MenuItem value="MG">MG</MenuItem>
                  <MenuItem value="PE">PE</MenuItem>
                  <MenuItem value="DBN">DBN</MenuItem>
                  <MenuItem value="PMB">PMB</MenuItem>
                  <MenuItem value="JHB">JHB</MenuItem>
                  <MenuItem value="BFK">BFK</MenuItem>
                  <MenuItem value="NAM">NAM</MenuItem>
                  <MenuItem value="ZIM">ZIM</MenuItem>
                  <MenuItem value="MAL">MAL</MenuItem>
                  <MenuItem value="MOZ">MOZ</MenuItem>
                  <MenuItem value="Social Media">Social Media</MenuItem>
                  {/* ... other referral codes ... */}
                </Select>
                <FormHelperText>
                  {errors["referralCode"] && errors["referralCode"].message}
                </FormHelperText>
              </FormControl>
              <p></p>
              {/* How did you find out about Equipped Text Field */}
              <FormLabel component="legend">{t("findOutQuestion")}</FormLabel>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="equipped-referral"
                  {...register("equippedReferral", {
                    required: "This field is required.",
                  })}
                  placeholder="Who referred you?"
                  variant="outlined"
                  fullWidth
                  error={!!errors.equippedReferral}
                  helperText={
                    errors.equippedReferral
                      ? errors.equippedReferral.message
                      : ""
                  }
                />
              </FormControl>

              <p></p>
              <div>{t("consentText")}</div>
              <p></p>
              <div>
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-checkbox-input"
                    checked={consent}
                    onChange={handleConsentChange}
                  />
                  <span class="custom-checkbox-label">{t("consent")}</span>
                </label>
              </div>

              {/* ... other inputs ... */}
              {!isSubmitting && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px", visibility: !isSubmitting }}
                  disabled={isSubmitting}
                >
                  {t("submit")}
                </Button>
              )}
              {isSubmitting && (
                <CircularProgress color="secondary" style={{}} />
              )}
            </form>
          </Container>

          <footer className="footer">
            <div className="footer-content">
              <div className="footer-column"></div>
              <div className="footer-column">
                <img
                  className="footer-logo"
                  src="https://equippedmedia.s3.af-south-1.amazonaws.com/img/footer.svg"
                  alt="Logo"
                />
              </div>
              <div className="footer-column"></div>
            </div>
            <div className="footer-info">
              Â© 2023 Equipped Media. All rights reserved. | Terms of Service |
              Privacy Policy
            </div>
          </footer>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
}
